import React from "react";
import './Faq.scss';
import Arrow2 from '../../Images/next.png'
import Review from '../../Images/Review.png'
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from '../../Images/Logo.png'
import closeMenu from '../../Images/closeMenu.png'

// images
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"


export default function Faq({ Blogs, FaqList }) {
    const navigate = useNavigate()
    const [faqlistFilter, setfaqlistFilter] = useState(FaqList);
    const [faqlistInputValue, setfaqlistInputValue] = useState("");
    function readingTime(S) {
        const text = S.Text;
        const wpm = 225;
        const words = text.trim().split(/\s+/).length;
        const time = Math.ceil(words / wpm);
        return time
    }
    const getSpecificNumberBlog = () => {
        const number = Math.random() * Blogs.length
        if (number <= (Blogs.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberBlog()
        }
    }
    const [getNumberBlog, setgetNumberBlog] = useState(getSpecificNumberBlog());
    const location = useLocation()
    useEffect(() => {
        // console.log(location.hash);
    }, []);

    return (


        <div className="Faq">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Questions and Answer | Corona, CA Heating & Air Conditioning</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Questions and Answer | Corona, CA Heating & Air Conditioning" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Questions and Answer | Corona, CA Heating & Air Conditioning" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* Blog Tags */}
                <meta name="PageType" content="article" />
                <meta name="ContentType" content="article" />
                <meta name="Category" content="article" />
                <meta name="SubCategories" content="article" />
                <meta name="FunnelStage" content="upper" />
                <meta name="Objective" content="education, seo" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.coronahvacairconditioning.org/faq" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Explore the Corona Heating & Air Conditioning Faq Page for insightful articles on strategies, tips, and expert advice. Discover innovative ways to Residential HVAC, secure your home." data-react-helmet="true" />                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Explore the Corona Heating & Air Conditioning Faq Page for insightful articles on strategies, tips, and expert advice. Discover innovative ways to Residential HVAC, secure your home." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Questions and Answer | Corona, CA Heating & Air Conditioning" data-react-helmet="true" />
                <meta name="keywords" content="HVAC services Corona CA, Air conditioning repair Corona, Heating repair Corona CA, HVAC installation Corona, Furnace repair Corona, AC maintenance Corona CA, Air duct cleaning Corona, Emergency HVAC services Corona, HVAC contractors Corona CA, Residential HVAC Corona, Commercial HVAC Corona, HVAC tune-up Corona, AC replacement Corona, Heating system installation Corona, Indoor air quality Corona CA, HVAC repair near me Corona, Central air conditioning Corona, Ductless mini-split Corona, HVAC service plan Corona, Thermostat installation Corona, 24/7 HVAC service Corona, Energy-efficient HVAC Corona, Air filter replacement Corona, Heat pump repair Corona, HVAC maintenance Corona CA, HVAC inspection Corona, Air conditioner installation Corona, Heating and cooling Corona, AC service Corona CA, HVAC system replacement Corona, Furnace installation Corona CA, HVAC repair cost Corona, Local HVAC company Corona, Affordable HVAC Corona, AC troubleshooting Corona, HVAC technicians Corona CA, HVAC emergency repair Corona, Heater repair Corona CA, Air conditioning services Corona, Commercial air conditioning Corona, Furnace tune-up Corona, Ductwork repair Corona CA, HVAC financing Corona, HVAC deals Corona CA, Heating and air conditioning Corona, HVAC consultation Corona, Central heating repair Corona, HVAC contractor near me Corona, Reliable HVAC Corona CA, Licensed HVAC contractor Corona" data-react-helmet="true" />
            </Helmet>
            <div className="FaqMainBanner">
                <h1>Heating & Air Conditioning | HVAC Questions</h1>
                <h2>For professional HVAC services, contact our HVAC company. Our certified technicians are dedicated to providing reliable and high-quality repairs to keep your heating and air conditioning systems safe and fully functional.</h2>
                <h5>Categories</h5>
                <div className="FaqMainBannerCategories">
                    <a href="/faq#ac-repair">AC Repair</a>
                    <a href="/faq#residential-hvac">Residential HVAC</a>
                    <a href="/faq#heating-and-cooling">Heating and Cooling</a>
                    <a href="/faq#hvac-services">HVAC Services</a>
                    <a href="/faq#cooling-system-repair">Cooling System Repair</a>
                    <a href="/faq#furnace-repair">Furnace Repair</a>
                    <a href="/faq#heat-pump-repair">Heat Pump Repair</a>
                </div>

            </div>
            {/* FaqMainList */}
            <div className="FaqMainList">
                {faqlistInputValue ?
                    <div className="FaqList">
                        <div className="FaqListHeaderResults">
                            <h6>Results</h6>
                            <h5 onClick={() => setfaqlistInputValue("")}>Restart Results</h5>
                        </div>
                        {faqlistFilter.filter(a =>
                            a.question.toLocaleLowerCase().includes(faqlistInputValue.toLocaleLowerCase()) ||
                            a.questionPath.toLocaleLowerCase().includes(faqlistInputValue.toLocaleLowerCase())
                        ).map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}

                        {faqlistFilter.length ?
                            <div className="FaqListNoResult">
                                <h1>We're sorry, but your search did not return any results.</h1>
                            </div>
                            : null}

                        <div className="FaqPageHeaderImagePromise">
                            <div className="FaqPageHeaderImagePromiseTop">
                                <div className="FaqPageHeaderImagePromiseTopLeft">
                                    <img src={Logo} alt="" />
                                    <h2>Corona Heating & Air Conditioning promise</h2>
                                </div>
                                <a href="/apply">More info</a>
                            </div>
                            <p>For professional HVAC Services services, contact Corona Sweep. Our certified technicians are dedicated to providing reliable and high-quality repairs to keep your Heating & Air Conditioningsafe and functional.</p>
                        </div>
                    </div>
                    :
                    <div className="FaqList">
                        <h6 id="ac-repair">AC Repair</h6>
                        {faqlistFilter.filter(a => a.category == "AC Repair").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <div className="FaqPageHeaderImagePromise">
                            <div className="FaqPageHeaderImagePromiseTop">
                                <div className="FaqPageHeaderImagePromiseTopLeft">
                                    <img src={Logo} alt="" />
                                    <h2>Corona Heating & Air Conditioning promise</h2>
                                </div>
                                <a href="/book">More info</a>
                            </div>
                            <p>For professional HVAC Services services, contact Corona Sweep. Our certified technicians are dedicated to providing reliable and high-quality repairs to keep your Heating & Air Conditioningsafe and functional.</p>
                        </div>
                        <h6 id="residential-hvac">Residential HVAC</h6>
                        {FaqList.filter(a => a.category == "Residential HVAC").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="heating-and-cooling">Heating and Cooling</h6>
                        {FaqList.filter(a => a.category == "Heating and Cooling").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="hvac-services">HVAC Services</h6>
                        {FaqList.filter(a => a.category == "HVAC Services").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="cooling-system-repair">Cooling System Repair</h6>
                        {FaqList.filter(a => a.category == "Cooling System Repair").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="furnace-repair">Furnace Repair</h6>
                        {FaqList.filter(a => a.category == "Residential HVAC").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="heat-pump-repair">Heat Pump Repair</h6>
                        {FaqList.filter(a => a.category == "Heat Pump Repair").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                    </div>
                }
                <aside className="FaqSearch">
                    <h1>Categories</h1>

                    <div className="FaqMainBannerCategories">
                        <a style={location.hash == "#ac-repair" ? { "borderBottom": "2px solid #0157ff" } : null} href="/faq#ac-repair">AC Repair</a>
                        <a style={location.hash == "#residential-hvac" ? { "borderBottom": "2px solid #0157ff" } : null} href="/faq#residential-hvac">Residential HVAC</a>
                        <a style={location.hash == "#heating-and-cooling" ? { "borderBottom": "2px solid #0157ff" } : null} href="/faq#heating-and-cooling">Heating and Cooling</a>
                        <a style={location.hash == "#hvac-services" ? { "borderBottom": "2px solid #0157ff" } : null} href="/faq#hvac-services">HVAC Services</a>
                        <a style={location.hash == "#cooling-system-repair" ? { "borderBottom": "2px solid #0157ff" } : null} href="/faq#cooling-system-repair">Cooling System Repair</a>
                        <a style={location.hash == "#furnace-repair" ? { "borderBottom": "2px solid #0157ff" } : null} href="/faq#furnace-repair">Furnace Repair</a>
                        <a style={location.hash == "#heat-pump-repair" ? { "borderBottom": "2px solid #0157ff" } : null} href="/faq#heat-pump-repair">Heat Pump Repair</a>
                    </div>
                    <h1>Search for question</h1>
                    <div className="FaqSearchInput">
                        <input
                            value={faqlistInputValue || ''}
                            onFocus={() => {
                                window.screen.width >= 850 ? window.scrollTo(0, 420) : window.scrollTo(0, 570)
                            }}
                            onChange={(e) => {
                                setfaqlistInputValue(e.target.value)
                                window.screen.width >= 850 ? window.scrollTo(0, 420) : window.scrollTo(0, 570)

                            }} type="text" placeholder="Search..." />
                        {faqlistInputValue ?
                            <img onClick={() => setfaqlistInputValue("")} src={closeMenu} alt="" />
                            :
                            null}
                    </div>
                </aside>
            </div>

            <div className="BlogsListReview">
                <div className="BlogsListReviewHead">
                    <h1>Related Articles</h1>
                </div>
                <div className="BlogsListReviewList">
                    {Blogs.slice(getNumberBlog, (getNumberBlog + 3)).map((A, index) =>
                        <a href={`/blog/${A.BlogPath.replace(/ /g, "_") + "_" + (A.BlogId - 1)}`} key={index} className="BlogsListReviewBox" >
                            <article>

                                <div className="BlogsListReviewBoxLeft">
                                    <img src={A.Photos} alt={A.BlogPath} />
                                </div>
                                <div className="BlogsListReviewBoxRight">
                                    <h1>{A.Title}</h1>
                                    <span>
                                        <h5>{readingTime(A)} MIN READ</h5>
                                        <h4>{A.Date}</h4>
                                    </span>
                                </div>
                            </article>

                        </a>
                    )}
                </div>
            </div>
            <div className="FaqApply">
                <h1>For professional HVAC Services services, contact Corona Sweep. Our certified technicians are dedicated to providing reliable and high-quality repairs to keep your Heating & Air Conditioningsafe and functional.</h1>
                <div className="FaqApplyBox">
                    <img src={Review} />
                    <h2> Submit your application within minutes <br /> No commitment required</h2>
                    <a href="/apply" >Apply Now</a>
                </div>

            </div>

            <NavigatorPath />

        </div >
    )
}

