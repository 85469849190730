import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Helmet } from "react-helmet";
import './SiteMap.scss';

// import { useLocation } from "react-router-dom";
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"
import { useEffect } from 'react';


export default function SiteMap({ Blogs, FaqList, WebSitePages }) {
    const location = useLocation()
    const [SiteMapToArray, setSiteMapToArray] = useState([]);


    useEffect(() => {
        if (location.pathname.split('/')[2] == "website" || location.pathname.split('/')[1] == "sitemaps" || location.pathname.split('/').length <= 2 && location.pathname.split('/')[1] == "sitemap") {
            setSiteMapToArray(WebSitePages)
        } else if (location.pathname.split('/')[2] == "solutions") {
            setSiteMapToArray(WebSitePages)
        } else if (location.pathname.split('/')[2] == "blog") {
            setSiteMapToArray(Blogs)
        } else if (location.pathname.split('/')[2] == "faq") {
            setSiteMapToArray(FaqList)
        } else if (location.pathname.split('/')[2] == "socialmedia") {
            setSiteMapToArray(WebSitePages)
        }
    }, [location.pathname]);

    return (
        <div className="SiteMap">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Corona Heating & Air Conditioning | Site Map</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Corona Heating & Air Conditioning | Site Map" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Corona Heating & Air Conditioning | Site Map" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.coronahvacairconditioning.org/sitemap" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Explore the comprehensive sitemap for Heating & Air Conditioning. Find detailed links to our Heating & Air Conditioninging, Repair, inspection, and repair services to ensure your home's safety and efficiency." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Explore the comprehensive sitemap for Heating & Air Conditioning. Find detailed links to our Heating & Air Conditioninging, Repair, inspection, and repair services to ensure your home's safety and efficiency." data-react-helmet="true" />
                <meta name="keywords" content="Heating & Air Conditioninging, Heating & Air Conditioning, Heating & Air Conditioninginspection, Heating & Air Conditioning repair, Heating & Air Conditioning maintenance, Creosote removal, Heating & Air Conditioningsafety, Heating & Air Conditioningflue Repair, Soot removal, Heating & Air Conditioning Maintenance services" data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Corona Heating & Air Conditioning | Site Map" data-react-helmet="true" />
            </Helmet>
            <div className="SiteMapMenu">
                <a href="/">Website</a>
            </div>
            <div className="SiteMapDes">
                <div className='SiteMapDesBox'>
                    <a href="/">Home Page</a>
                    <a href="/contact">Contact Us</a>
                    <a href="/book">Book Heating & Air Conditioning Appointment Online In Corona, California</a>
                    <a href="/blog">Blog</a>
                    <a href="/faq">Faq</a>
                    <a href="/sitemap">Site Map</a>

                    <a className="Header1BoxMenuBox" href="/service/Air-Conditioning-Repair">Air Conditioning Repair In Corona, CA</a>
                    <a className="Header1BoxMenuBox" href="/service/Ac-Services">Ac Services In Corona, CA</a>
                    <a className="Header1BoxMenuBox" href="/service/Hvac-Repair-Near-Me">Hvac Repair Near Me In Corona, CA</a>
                    <a className="Header1BoxMenuBox" href="/service/Ac-Repair">Ac Repair In Corona, CA</a>
                    <a className="Header1BoxMenuBox" href="/service/Ac-Installation">Ac Installation In Corona, CA</a>
                    <a className="Header1BoxMenuBox" href="/service/Hvac-Services">Hvac Services In Corona, CA</a>
                    <a className="Header1BoxMenuBox" href="/service/Air-Conditioning-Service">Air Conditioning Service In Corona, CA</a>
                    <a className="Header1BoxMenuBox" href="/service/Furnace-Repair">Furnace Repair In Corona, CA</a>
                    <a className="Header1BoxMenuBox" href="/service/Heating-And-Cooling">Heating And Cooling In Corona, CA</a>
                    <a className="Header1BoxMenuBox" href="/service/Ac-Maintenance">Ac Maintenance In Corona, CA</a>
                    <a className="Header1BoxMenuBox" href="/service/Emergency-Hvac-Service">Emergency Hvac Service In Corona, CA</a>
                    <a className="Header1BoxMenuBox" href="/service/Duct-Cleaning">Duct Cleaning In Corona, CA</a>
                    <a className="Header1BoxMenuBox" href="/service/Indoor-Air-Quality">Indoor Air Quality In Corona, CA</a>
                    <a className="Header1BoxMenuBox" href="/service/Hvac-Tune-up">Hvac Tune-up In Corona, CA</a>
                    <a className="Header1BoxMenuBox" href="/service/Heat-Pump-Repair">Heat Pump Repair In Corona, CA</a>
                    <a className="Header1BoxMenuBox" href="/service/Hvac-Contractors">Hvac Contractors In Corona, CA</a>
                    <a className="Header1BoxMenuBox" href="/service/Air-Conditioning-Installation">Air Conditioning Installation In Corona, CA</a>
                    <a className="Header1BoxMenuBox" href="/service/Heating-System-Repair">Heating System Repair In Corona, CA</a>
                    <a className="Header1BoxMenuBox" href="/service/Central-Air-Conditioning">Central Air Conditioning In Corona, CA</a>
                    <a className="Header1BoxMenuBox" href="/service/Residential-Hvac">Residential Hvac In Corona, CA</a>
                    <a className="Header1BoxMenuBox" href="/service/Commercial-Hvac-Services">Commercial Hvac Services In Corona, CA</a>
                    <a className="Header1BoxMenuBox" href="/service/Thermostat-Installation">Thermostat Installation In Corona, CA</a>
                    <a className="Header1BoxMenuBox" href="/service/Energy-efficient-Hvac">Energy-efficient Hvac In Corona, CA</a>
                    <a className="Header1BoxMenuBox" href="/service/Ventilation-Services">Ventilation Services In Corona, CA</a>
                    <a className="Header1BoxMenuBox" href="/service/Hvac-Installation">Hvac Installation In Corona, CA</a>
                    <a className="Header1BoxMenuBox" href="/service/Hvac-Repair">Hvac Repair In Corona, CA</a>
                    <a className="Header1BoxMenuBox" href="/service/Ac-Repair-Near-Me">Ac Repair Near Me In Corona, CA</a>
                    <a className="Header1BoxMenuBox" href="/service/Condenser-Repair">Condenser Repair In Corona, CA</a>
                    <a className="Header1BoxMenuBox" href="/service/Compressor-Repair">Compressor Repair In Corona, CA</a>
                    <a className="Header1BoxMenuBox" href="/service/Evaporatoer-Repair">Evaporatoer Repair In Corona, CA</a>
                    <a className="Header1BoxMenuBox" href="/service/Cooling-System-Repair">Cooling System Repair In Corona, CA</a>
                    <a className="Header1BoxMenuBox" href="/service/Air-Duct-Cleaning">Air Duct Cleaning In Corona, CA</a>
                    <a className="Header1BoxMenuBox" href="/service/Dryer-Vent-Cleaning">Dryer Vent Cleaning In Corona, CA</a>

                    {/* faq */}
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_are_common_signs_that_my_AC_needs_repair_in_Corona_CA_0">What are common signs that my AC needs repair in Corona CA</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_long_does_a_typical_AC_repair_take_in_Corona_CA_1">How long does a typical AC repair take in Corona CA</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/Can_I_continue_to_use_my_AC_if_it’s_making_strange_noises_in_Corona_CA_2">Can I continue to use my AC if it’s making strange noises in Corona CA</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_much_does_AC_repair_typically_cost_in_Corona_CA_3">How much does AC repair typically cost in Corona CA</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_should_I_do_if_my_AC_is_leaking_water_in_Corona_CA_4">What should I do if my AC is leaking water in Corona CA</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_often_should_residential_HVAC_systems_be_serviced_in_Corona_CA_5">How often should residential HVAC systems be serviced in Corona CA</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_are_the_benefits_of_a_residential_HVAC_maintenance_plan_in_Corona_CA_6">What are the benefits of a residential HVAC maintenance plan in Corona CA</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_size_HVAC_system_do_I_need_for_my_home_in_Corona_CA_7">What size HVAC system do I need for my home in Corona CA</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/Can_I_improve_energy_efficiency_with_my_existing_HVAC_system_in_Corona_CA_8">Can I improve energy efficiency with my existing HVAC system in Corona CA</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_is_the_lifespan_of_a_residential_HVAC_system_in_Corona_CA_9">What is the lifespan of a residential HVAC system in Corona CA</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_can_I_reduce_heating_and_cooling_costs_in_my_Corona,_C_home_10">How can I reduce heating and cooling costs in my Corona, C home</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_is_the_difference_between_a_heat_pump_and_a_furnace_in_Corona_CA_11">What is the difference between a heat pump and a furnace in Corona CA</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_do_I_know_if_my_heating_and_cooling_system_is_energy-efficient_in_Corona_CA_12">How do I know if my heating and cooling system is energy-efficient in Corona CA</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/Why_is_my_heating_and_cooling_system_cycling_on_and_off_frequently_in_Corona_CA_13">Why is my heating and cooling system cycling on and off frequently in Corona CA</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_often_should_I_change_my_HVAC_system’s_air_filters_in_Corona_CA_14">How often should I change my HVAC system’s air filters in Corona CA</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_is_included_in_a_standard_HVAC_service_visit_in_Corona_CA_15">What is included in a standard HVAC service visit in Corona CA</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_do_I_choose_the_right_HVAC_service_provider_in_Corona_CA_16">How do I choose the right HVAC service provider in Corona CA</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_are_the_benefits_of_regular_HVAC_maintenance_in_Corona_CA_17">What are the benefits of regular HVAC maintenance in Corona CA</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_can_I_tell_if_my_HVAC_system_needs_to_be_replaced_rather_than_repaired_in_Corona_CA_18">How can I tell if my HVAC system needs to be replaced rather than repaired in Corona CA</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_types_of_HVAC_systems_are_available_for_homes_in_Corona_CA_19">What types of HVAC systems are available for homes in Corona CA</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/Why_is_my_cooling_system_blowing_warm_air_in_Corona_CA_20">Why is my cooling system blowing warm air in Corona CA</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_should_I_do_if_my_cooling_system_is_not_turning_on_in_Corona_CA_21">What should I do if my cooling system is not turning on in Corona CA</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_can_I_prevent_cooling_system_breakdowns_in_Corona_CA_22">How can I prevent cooling system breakdowns in Corona CA</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_are_common_causes_of_cooling_system_leaks_in_Corona_CA_23">What are common causes of cooling system leaks in Corona CA</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/Can_I_repair_my_cooling_system_myself_in_Corona_CA_24">Can I repair my cooling system myself in Corona CA</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_are_signs_that_my_furnace_needs_repair_in_Corona_CA_25">What are signs that my furnace needs repair in Corona CA</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_often_should_a_furnace_be_serviced_in_Corona_CA_26">How often should a furnace be serviced in Corona CA</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/Why_is_my_furnace_making_a_banging_noise_in_Corona_CA_27">Why is my furnace making a banging noise in Corona CA</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/Can_I_still_use_my_furnace_if_it_smells_like_gas_in_Corona_CA_28">Can I still use my furnace if it smells like gas in Corona CA</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_much_does_furnace_repair_typically_cost_in_Corona_CA_29">How much does furnace repair typically cost in Corona CA</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_should_I_do_if_my_heat_pump_isn’t_heating_my_home_properly_in_Corona_CA_30">What should I do if my heat pump isn’t heating my home properly in Corona CA</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/Why_is_my_heat_pump_making_strange_noises_in_Corona_CA_31">Why is my heat pump making strange noises in Corona CA</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_often_should_heat_pumps_be_serviced_in_Corona_CA_32">How often should heat pumps be serviced in Corona CA</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/Why_is_my_heat_pump_freezing_up_in_Corona_CA_33">Why is my heat pump freezing up in Corona CA</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/Can_I_repair_my_heat_pump_myself_in_Corona_CA_34">Can I repair my heat pump myself in Corona CA</a>

                    {/* blog */}
                    <a className='Header1BoxMenuBox' href="/blog/complete_hvac_services_in_Corona,_ca:_ensuring_comfort_and_efficiency_all_year_round_3">Complete HVAC Services in Corona, CA: Ensuring Comfort and Efficiency All Year Round</a>
                    <a className='Header1BoxMenuBox' href="/blog/comprehensive_heating_and_cooling_services_in_Corona,_ca:_your_guide_to_year-round_comfort_2">Comprehensive Heating and Cooling Services in Corona, CA: Your Guide to Year-Round Comfort</a>
                    <a className='Header1BoxMenuBox' href="/blog/residential_hvac_services_in_Corona,_ca:_ensuring_year-round_comfort_and_efficiency_1">Residential HVAC Services in Corona, CA: Ensuring Year-Round Comfort and Efficiency</a>
                    <a className='Header1BoxMenuBox' href="/blog/comprehensive_guide_to_ac_repair_in_Corona,_ca:_keeping_your_home_cool_and_comfortable_0">Comprehensive Guide to AC Repair in Corona, CA: Keeping Your Home Cool and Comfortable</a>
                </div>



            </div>
            <NavigatorPath />
        </div>
    )
}
